import useEvent from "../Hooks/useEvents";
import { createContext, useContext } from "react";

const eventCtx = createContext();
export default function EventProvider({ children }) {
    return (
        <eventCtx.Provider value={{ ...useEvent() }}>
            {children}
        </eventCtx.Provider>
    );
}
export const useEventCtx = () => useContext(eventCtx);