import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Context from './Features/Context';
import './index.css';
import reportWebVitals from './reportWebVitals';

//eslint-disable-next-line
Date.prototype.addDays = function (days) {
  let date = new Date(this.toDateString());
  date.setDate(this.getDate() + days);
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Context>
      <App />
    </Context>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
