import { io } from 'socket.io-client';
import useUtils from '../Utils/useUtils';
import { useEffect } from 'react';

const socket = io(process.env.REACT_APP_SOCKET_URL, { autoConnect: true });

export default function useEvent() {
    const { hitToast } = useUtils();

    useEffect(() => {
        socket.on('connection', (data) => {

        });

        socket.on('status', (data) => {
            hitToast(data.reason, data.type || 'info');
        });

        return () => {
            socket.off('connection');
            socket.off('status');
        };
    }, []);

    return {
        socket
    };

}