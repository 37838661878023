import MenuIcon from '@mui/icons-material/Menu';
import RoofingIcon from '@mui/icons-material/Roofing';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Logo from '../../../assets/logo.png';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthCtx } from '../../../Contexts/AuthProvider';

const settings = [
    {
        name: 'Profile',
        path: '/profile',
    },
    {
        name: 'Dashboard',
        path: '/dashboard'
    }
];

function NavBar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const { user, signOut } = useAuthCtx();
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSignOut = () => {
        signOut();
        handleCloseUserMenu();
    };

    return (
        <AppBar sx={{ backgroundColor: 'black' }} position="static" elevation={0}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <figure className='w-20 h-20'>
                        <img src={Logo} alt="logo" />
                    </figure>
                    <Typography
                        onClick={() => navigate('/dashboard/projects')}
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        URBAN OASIS
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <RoofingIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LOGO
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    </Box>

                    {
                        user.id ? <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title={user?.userName}>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={user.userName} src={`${process.env.REACT_APP_SERVER_URL}/${user.avatar || ''}`} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                                        <Link to={setting.path}><Typography textAlign="center">{setting.name}</Typography></Link>
                                    </MenuItem>
                                ))}
                                <MenuItem>
                                    {
                                        user?.userName ? <Typography textAlign="center"><span onClick={handleSignOut}>Logout</span></Typography> : <Link to='/user/signin'><Typography textAlign="center">Login</Typography></Link>
                                    }
                                </MenuItem>

                            </Menu>
                        </Box> : <></>
                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;