import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import NavBar from './Components/Shared/NavBar/NavBar';
import Routing from './Features/Routing';
import { useLocation } from 'react-router-dom';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const location = useLocation();
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div>
        <header>
          {
            location.pathname !== '/pdf-view' ? <NavBar /> : <></>
          }
        </header>
        <main>
          <Routing />
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
