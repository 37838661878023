import { useEffect, useState } from "react";
import useUtils from "../Utils/useUtils";
import req from "./req";
import { useNavigate } from "react-router-dom";
import { useAuthCtx } from "../Contexts/AuthProvider";

const uniq = () => 'id' + (new Date()).getTime() + Math.ceil(Math.random() * 1000).toString();

const useCategory = () => {
    const [categories, setCategories] = useState([]);
    const [subCategory, setSubCategory] = useState({ [uniq()]: '' });
    const { hitToast } = useUtils();
    const { user } = useAuthCtx();
    const navigate = useNavigate();

    useEffect(() => {
        req({ method: 'GET', uri: 'categories' })
            .then(({ data }) => {
                setCategories(data);
            })
            .catch((err) => console.log(err));
    }, [user]);

    const addCategory = (payload, reset) => {
        req({ method: 'POST', uri: 'category', data: payload })
            .then(({ data }) => {
                setCategories((prev) => ([data, ...prev]));
                hitToast('Created Successfully', 'success');
                setSubCategory({ [uniq()]: '' });
                reset();
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    const updateCategory = (id, payload) => {
        req({ method: 'PATCH', uri: `category/${id}`, data: payload })
            .then(({ data }) => {
                setCategories(([...prev]) => prev.map((p) => p.id === id ? data : p));
                hitToast('Updated Successfully', 'success');
                navigate('/dashboard/categories')
            })
            .catch((err) => {
                console.log(err);
                hitToast('Failed to update', 'error');
            });
    };

    const removeCategory = (id) => {
        req({ method: 'DELETE', uri: `category/${id}` })
            .then(() => {
                setCategories((prev) => prev.filter((p) => p.id !== id));
                hitToast('Deleted Successfully', 'success');
            })
            .catch((err) => {
                console.log(err);
                hitToast('Failed to delete', 'error');
            });
    };

    return {
        categories,
        setCategories,
        subCategory,
        setSubCategory,
        addCategory,
        updateCategory,
        removeCategory
    };
};

export default useCategory;