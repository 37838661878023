import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from '../Components/Shared/Loader/Loader';
import PrivateRoute from './PrivateRoute';
const Pdf = lazy(() => import('./Quotation2/Pdf'));
const Quotation2 = lazy(() => import('./Quotation2/Quotation2'));;
const UpdateCategory = lazy(() => import('./Category/UpdateCategory'));
const CreateCategory = lazy(() => import('./Category/CreateCategory'));
const Categories = lazy(() => import('./Category/Categories'));
const ProjectTable = lazy(() => import('../Pages/Projects/ProjectTable'));
const Quote = lazy(() => import('./Quotation/Quote'));
const Users = lazy(() => import('../Pages/Users/User'));
const Dashboard = lazy(() => import('./Dashboard'));
const SingleProject = lazy(() => import('../Pages/Projects/Components/SingleProject'));
const Profile = lazy(() => import('../Pages/Profile/Profile'))
const SignIn = lazy(() => import('../Pages/SignIn/SignIn'));
// const Quotation = lazy(() => import('../Pages/Quotation/Quotation'));

export default function Routing() {
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path='/user/signin' element={<SignIn />} />
                <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
                <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} >
                    <Route index element={<Users />} />
                    <Route path='projects' element={<ProjectTable />} />
                    <Route path='projects/:id' element={<SingleProject />} />
                    <Route path='quotation' element={<Quotation2 />} />
                    <Route path='categories' element={<Categories />} />
                    <Route path='create-category' element={<CreateCategory />} />
                    <Route path='update-category/:id' element={<UpdateCategory />} />
                </Route>
                <Route path='/generate-quotation' element={<Quote />} />
                <Route path="/pdf-view" element={<Pdf />} />
            </Routes>
        </Suspense>
    );
};
