import { useEffect, useState } from "react";
import useUtils from "../Utils/useUtils";
import req from "./req";

const useGlobal = () => {
    const [users, setUsers] = useState([]);
    const [tabName, setTabName] = useState('User List');
    const [isLoading, setIsLoading] = useState(false);
    const [quoteData, setQuoteData] = useState({});
    const { hitToast } = useUtils();

    useEffect(() => {
        req({ method: 'GET', uri: 'user?paginate=false' })
            .then(({ data }) => {
                setUsers(data);
            })
            .catch((err) => console.log(err));
    }, []);

    const createUser = (data) => {
        req({ method: 'POST', uri: 'user', data })
            .then(({ data: res }) => {
                hitToast('User added successfully', 'success');
                setUsers((prev) => ([res, ...prev]));
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    const updateUser = (d, id) => {
        const image = d.avatr;
        if (image) delete d.avatar;
        const payLoad = {
            avatar: image,
            data: JSON.stringify(d)
        };
        if (!payLoad.image) delete payLoad.avatar;
        req({ method: 'PATCH', uri: `user/${id}`, data: payLoad })
            .then(({ data: res }) => {
                hitToast('User updated successfully', 'success');
                setUsers(([...prev]) => {
                    return prev.map((p) => p.id !== res.id ? p : res);
                });
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            })
    };

    const removeUser = (id) => {
        req({ method: 'DELETE', uri: `user/${id}` })
            .then(() => {
                setUsers(([...prev]) => prev.filter(p => p.id !== id));
                hitToast('Deleted Successfully', 'success');
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    return {
        users,
        setUsers,
        isLoading,
        setIsLoading,
        createUser,
        tabName,
        setTabName,
        updateUser,
        removeUser,
        quoteData,
        setQuoteData
    };
};
export default useGlobal;