import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthCtx } from '../Contexts/AuthProvider';
import useUtils from '../Utils/useUtils';
import req from './req';

const useProjects = () => {
    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [subTasks, setSubtasks] = useState([]);
    const [type, setType] = useState('today');
    const [isLoading, setIsLoading] = useState(false);
    const { hitToast } = useUtils();
    const { user } = useAuthCtx();
    const navigate = useNavigate();

    useEffect(() => {
        let uri = 'project?paginate=false';
        if (user.role === 'manager') uri = `project?paginate=false&manager=${user.id}`;
        req({ method: 'GET', uri })
            .then(({ data }) => {
                setProjects(data);
            })
            .catch((er) => console.log(er));
    }, [user.id]);

    const createproject = (payload) => {
        req({ method: 'POST', uri: 'project', data: payload })
            .then((res) => {
                hitToast('Project created', 'success');
                setProjects(([...prev]) => ([res.data, ...prev]));
                navigate(`/dashboard/projects/${res.data.id}`, { replace: true });
            })
            .catch((err) => {
                console.log(err);
                hitToast(err.response.data.message || 'Something went wrong', 'error');
            });
    };

    const updateProject = (id, payload, e) => {
        e.stopPropagation();
        req({ method: 'PATCH', uri: `project/${id}`, data: payload })
            .then((res) => {
                hitToast("Updated Project", 'success');
                setProjects(([...prev]) => {
                    return prev.map((p) => p.id === res.data.id ? res.data : p);
                });
            })
            .catch((err) => {
                console.log(err);
                hitToast(err.response.data.message || 'Something went wrong', 'error');
            });
    };

    const deleteProject = (id, e) => {
        e.stopPropagation();
        req({ method: 'DELETE', uri: `project/${id}` })
            .then(() => {
                hitToast('Deleted Successfully', 'success');
                setProjects(([...prev]) => prev.filter(p => p.id !== id));
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    const createTask = (payload) => {
        req({ method: 'POST', uri: 'task', data: payload })
            .then(({ data }) => {
                if (data.type === 'task') setTasks(([...prev]) => ([data, ...prev]));
                if (data.type === 'subTask') setSubtasks(([...prev]) => ([data, ...prev]));
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    const fetchTaskById = (id) => {
        req({ method: 'GET', uri: `task/${id}?paginate=false&type=task` })
            .then(({ data }) => {
                setTasks(data);
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    const deleteTask = (id) => {
        req({ method: 'DELETE', uri: `task/${id}` })
            .then(({ data }) => {
                hitToast('Deleted Successfully', 'success');
                if (data.type === 'task') setTasks(([...prev]) => prev.filter((p) => p.id !== data.id));
                if (data.type === 'subTask') setSubtasks(([...prev]) => prev.filter((p) => p.id !== data.id));
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    const fetchSubTaskById = (parentId, projectId) => {
        let uri = `task/${projectId}?paginate=false&type=subTask&parent=${parentId}`;
        const nextDay = JSON.stringify({ $gte: (new Date()).addDays(2), $lte: (new Date()).addDays(2) });
        const today = JSON.stringify({ $gte: `${new Date().toISOString().split('T')[0]}T00:00:00.000Z`, $lte: `${new Date().toISOString().split('T')[0]}T23:59:59.999Z` });
        const deadLine = type === 'today' ? today : nextDay;
        if (user.role === 'manager') uri = `task/${projectId}?paginate=false&type=subTask&parent=${parentId}&status=inProgress&status=extended&status=complete&deadLine=${deadLine}`;
        req({ method: 'GET', uri })
            .then(({ data }) => {
                setSubtasks(data);
                if (type === 'nextDay' && user.role === 'manager') {
                    const queryDate = JSON.stringify({ $gt: (new Date()).addDays(2) });
                    const query = `task/${projectId}?paginate=false&type=subTask&parent=${parentId}&status=extended&deadLine=${queryDate}`;
                    req({ method: 'GET', uri: query })
                        .then(({ data: res }) => {
                            setTasks(([...prev]) => ([...prev, res]));
                        })
                        .catch((err) => console.log(err));
                }
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    const updateTask = (payload, id) => {
        req({ method: 'PATCH', uri: `task/${id}`, data: payload })
            .then(({ data: res }) => {
                hitToast('Updated Successfully', 'success');
                if (res.type === 'subTask') {
                    setSubtasks(([...prev]) => {
                        return prev.map((p) => p.id !== res.id ? p : res);
                    });
                }
                if (res.type === 'task') {
                    setTasks(([...prev]) => {
                        return prev.map((p) => p.id !== res.id ? p : res);
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    return {
        type,
        setType,
        projects,
        setProjects,
        isLoading,
        setIsLoading,
        createproject,
        createTask,
        fetchTaskById,
        tasks,
        setTasks,
        deleteTask,
        subTasks,
        setSubtasks,
        fetchSubTaskById,
        updateTask,
        updateProject,
        deleteProject
    }
};
export default useProjects;