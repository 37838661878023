import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export default function Loader() {
    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <CircularProgress />
        </div>
    );
};
