import { useSnackbar } from "notistack";

/**
* Custom hook for utility functions.
* @returns {object} - An object containing utility functions.
*/
const useUtils = () => {
    const { enqueueSnackbar } = useSnackbar();

    /**
    * Displays a toast message using the Snackbar component from the notistack library.
    * @param {string} message - The message to be displayed.
    * @param {string} variant - The variant of the toast message (success, error, info, warning).
    * @returns {void} - This function does not return a value.
    */
    const hitToast = (message, variant) => {
        if (!['success', 'error', 'info', 'warning'].includes(variant)) return enqueueSnackbar('Invalid variant!', { variant: 'error' });
        return enqueueSnackbar(message, { variant });
    };

    const validatePayload = (keys, data) => {
        let missingKeys = [];

        keys.forEach((key) => {
            if (!(key in data) || data[key] === null || data[key] === undefined) {
                missingKeys.push(key);
            }
        });

        if (missingKeys.length > 0) {
            missingKeys = missingKeys.map((k) => k.charAt(0).toUpperCase() + k.slice(1));
            const message = `The following keys are required: ${missingKeys.join(',')}`;
            return {
                isValid: false,
                message
            };
        }
        else return {
            isValid: true,
        };
    };

    return {
        hitToast,
        validatePayload
    }
}

export default useUtils;