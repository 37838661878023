import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useUtils from '../Utils/useUtils';
import req from './req';

const useAuth = () => {
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { hitToast } = useUtils();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        req({ method: 'GET', uri: 'user/me' })
            .then(({ data }) => {
                setUser(data);
                if (pathname === '/') navigate('/dashboard/projects');
            })
            .catch((err) => {
                console.log(err);
                navigate('/user/signin');
            })
            .finally(() => setIsLoading(false));
    }, [isLoading]);

    const signinUser = (data) => {
        req({ method: 'POST', uri: 'user/login', data })
            .then(({ data }) => {
                setUser(data);
                navigate('/dashboard/projects');
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            })
    };

    const signOut = () => {
        req({ method: 'POST', uri: 'user/logout' })
            .then(() => {
                navigate('/user/signin');
                setUser({});
            })
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };


    const updateUser = (data) => {
        req({ method: 'PATCH', uri: 'user/me', data })
            .then((({ data }) => {
                hitToast('Updated', 'success');
                setUser(data);
            }))
            .catch((err) => {
                console.log(err);
                hitToast('Something went wrong', 'error');
            });
    };

    return {
        user,
        setUser,
        isLoading,
        setIsLoading,
        signinUser,
        signOut,
        updateUser,
    };
};

export default useAuth;