import React, { createContext, useContext } from 'react';
import useGlobal from '../Hooks/useGlobal';
import useProjects from '../Hooks/useProjects';
import useCategory from '../Hooks/useCategory';

const globalContext = createContext();
export default function GlobalProvider({ children }) {
    return (
        <globalContext.Provider value={{ ...useGlobal(), ...useProjects(), ...useCategory() }}>
            {children}
        </globalContext.Provider>
    );
};
export const useGlobalCtx = () => useContext(globalContext);