import React, { createContext, useContext } from 'react';
import useAuth from '../Hooks/useAuth';

const authContext = createContext();
export default function AuthProvider({ children }) {
    const data = useAuth();
    return (
        <authContext.Provider value={data}>
            {children}
        </authContext.Provider>
    );
};
export const useAuthCtx = () => useContext(authContext);
