import React from 'react';
import { SnackbarProvider } from 'notistack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AuthProvider from '../Contexts/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import GlobalProvider from '../Contexts/GlobalProvider';
import EventProvider from '../Contexts/EventProvider';

export default function Context({ children }) {
	return (
		<BrowserRouter>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<SnackbarProvider dense autoHideDuration={3000} maxSnack={3} anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
					<AuthProvider>
						<EventProvider>
							<GlobalProvider>
								{children}
							</GlobalProvider>
						</EventProvider>
					</AuthProvider>
				</SnackbarProvider>
			</LocalizationProvider>
		</BrowserRouter>
	);
};
